.wrapper {
  width: calc(20% - 6px);
  padding: 6px;
  background: white;
  border-radius: 6px;
  cursor: pointer;
}

.newLabel {
  position: absolute;
  top: 7px;
  left: 7px;
  padding: 5px 8px;
  color: white;
  font-weight: 600;
  font-size: 10px;
  background: #8f00ff;
  border-radius: 4px;
}

.logo {
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 17%;
  height: 17%;
  overflow: hidden;
  background: white;
  border-radius: 50%;
  box-shadow: 0 0 0 1px #f7f7f7;
}

.logoImg {
  width: 100%;
}

.picture {
  position: relative;
  margin-bottom: 8px;
  overflow: hidden;
  background: white;
  border-radius: 6px;
}

.pictureImg {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.emptyPictureImg {
  width: 100%;
  margin-bottom: 34px;
  color: #d8aff6;
}

.emptyPictureText {
  display: flex;
  width: 110px;
  color: #d8aff6;
  text-align: center;
}

.description,
.price,
.text {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.type {
  display: inline-flex;
  margin-bottom: 8px;
  padding: 4px 8px;
  color: #d9d9d9;
  font-size: 10px;
  text-transform: uppercase;
  border-radius: 99px;
  box-shadow: 0 0 0 1px #d9d9d9;
}

.description {
  margin-bottom: 3px;
  color: #8f00ff;
  font-weight: 500;
  font-size: 15px;
}

.text {
  margin-bottom: 14px;
  color: #585858;
  font-size: 12px;
  cursor: pointer;

  &:hover {
    color: #3c4fff;
    text-decoration: underline;
  }
}

.price {
  font-weight: 600;
  font-size: 20px;
}

.button {
  width: 100%;
}

@media (width <= 960px) {
  .wrapper {
    width: calc(25% - 6px);
  }
}

@media (width <= 680px) {
  .wrapper {
    width: calc(33.33% - 6px);
  }
}

@media (width <= 480px) {
  .wrapper {
    width: calc(50% - 6px);
  }
}
