.wrapper {
  position: relative;
}

.arrowLeft,
.arrowRight {
  position: absolute;
  top: 50%;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 52px;
  height: 52px;
  background: white;
  border-radius: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  opacity: 0.8;
  user-select: none;
}

.arrowLeft {
  left: 40px;
}

.arrowRight {
  right: 40px;
}

.carousel {
  height: 100% !important;
  background: white !important;
  border-radius: 10px;

  & div:last-child > ul > li > input:checked ~ label {
    transform: translateY(-1px);
  }
}

.img {
  width: 100%;
  background: white !important;
}

@media (width <= 480px) {
  .arrowLeft,
  .arrowRight {
    width: 30px;
    height: 30px;
  }

  .arrowLeft {
    left: 25px;
  }

  .arrowRight {
    right: 25px;
  }
}
