.wrapper {
  transition: 300ms opacity ease-in-out;
  opacity: 0;
  position: relative;
  display: flex;
  width: 100%;
}

.showOpacity {
  opacity: 1;
}

.loadingIng {
  width: 100%;
}

.mainIng {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  animation: 500ms ease-in-out show-opacity;
}
