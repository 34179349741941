@import "../../styles/mixins/forsom";

.wrapper {
  flex-grow: 1;
}

.carouselWrapper {
  @include container_max-width_1440;

  margin: 18px auto 0;
  padding: 0 18px;
}

.cards {
  @include container_max-width_1440;

  display: flex;
  flex-wrap: wrap;
  gap: 18px 7px;
  padding: 18px;
}

.loaderWrapper {
  @include centerAbsolut;
}

.loader {
  width: 50px;
  height: 50px;
}
